import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import Style from './index.module.scss'

const Page = () => (
  <div className={Style.markdownBody} style={{ margin: '16px' }}>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>TermsOfService</title>
      <link
        rel="icon"
        type="image/x-icon"
        href={`${withPrefix('/')}img/recipe-note-icon.png`}
      />
    </Helmet>
    <h1>プライバシーポリシー</h1>
    <section>
      <p>
        本アプリ(RECIPE NOTE)では以下に記載する事項に基づき、お客様の利用情報を取り扱います。
      </p>
      <p>
        お客様が本アプリのご利用をされるためには、以下に記載する事項を熟読のうえ、内容に同意していただく必要があります。
      </p>
      <p>同意いただけない場合は、本アプリのご利用はお断り致します。</p>
    </section>
    <section>
      <h2>利用情報について</h2>
      <p>利用情報とは、本アプリの利用に関する以下の情報をいうものとします。</p>
      <ol>
        <li>
          お客様がご利用になった本アプリの内容、ご利用日時、ご利用回数、本アプリ利用時のお客様の挙動などのご利用内容・履歴・状況に関する情報
        </li>
        <li>お客さまの位置情報</li>
        <li>
          お客様が本サービスで検索した情報、保存した写真、コメント、評価、レシピ、その他の情報
        </li>
      </ol>
    </section>
    <section>
      <h2>取得情報の収集について</h2>
      <p>
        本アプリをご利用になるお客様の利用情報（以下「取得情報」とします。）を以下の方法により、収集させていただく場合がございます。
      </p>
      <ol>
        <li>
          お客様が本サービスをご利用された際に履歴等を収集させていただくことがございます。
        </li>
        <li>
          これらの情報にはお客様が利用されるＵＲＬ、ブラウザや携帯電話の種類やIPアドレス、お客様の位置情報などの情報を含みます。
        </li>
      </ol>
    </section>
    <section>
      <h2>取得情報の管理について</h2>
      <p>
        お客様の取得情報について厳重に管理を行い、利用情報への不当なアクセス、紛失、漏洩、改ざん等が起きないよう、取得情報の取扱に十分な注意を払い、その業務に努めます。
      </p>
    </section>
    <section>
      <h2>取得情報の利用目的について</h2>
      <p>以下の目的のために、取得情報を利用させていただきます。</p>
      <ol>
        <li>本サービスに関する各種事項の連絡や情報提供を行うため</li>
        <li>本サービスを提供するため</li>
        <li>本サービスの利用状況等を調査・分析するため</li>
        <li>
          本サービスの内容をよりご満足いただけるよう改良・改善、又は新サービス開発のため
        </li>
        <li>本サービスの内容をお客様に合わせてカスタマイズするため</li>
        <li>本サービスに関する満足度を調査するため</li>
        <li>
          取得情報を統計的に処理した情報を、集約し調査結果として公表するため
        </li>
        <li>本サービス運営上のトラブルの解決のため</li>
      </ol>
    </section>
    <section>
      <h2>取得情報の第三者への開示について</h2>
      <p>以下に定める場合、取得情報を第三者に開示します。</p>
      <ol>
        <li>
          本アプリを提供するために当社が必要と判断した、本アプリ上での情報の開示の場合。
        </li>
        <li>当社がサービスの運営及び提供において必要と判断した場合</li>
        <li>
          お客様からのお問い合わせへの対応、当社からお客様へのお問い合わせ、関連するアフターサービスの提供等のために、第三者に開示する場合。
        </li>
        <li>
          法令等に基づき、裁判所、行政機関、監督官庁その他公的機関から取得情報を提供するよう求められた場合。
        </li>
        <li>
          第三者との紛争解決のため、又は本サービスのユーザーもしくは第三者の権利や利益等を守るために情報開示が必要と当社が判断した場合。
        </li>
        <li>
          本サービスの利用状況等を調査・分析するため、当社がかかる調査・分析等を委託する第三者に開示する場合。
        </li>
        <li>
          当社が利用目的を達成するために必要な範囲で、個人情報の取扱いの全部又は一部を委託する場合。
        </li>
        <li>当社又は第三者の広告の配信又は表示のため</li>
        <li>
          その他、当社が取得情報の開示が必要と判断した場合に、お客様の同意を得た上で、取得情報を開示させていただくことがございます。
        </li>
      </ol>
    </section>
    <section>
      <h2>利用情報の管理およびセキュリティについて</h2>
      <p>
        当社では、収集させていただいた取得情報を、一般のユーザーがアクセスできない環境下にあるサーバーにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは漏洩が生じないよう努めます。
      </p>
    </section>
    <section>
      <h2>免責・注意事項</h2>
      <p>
        当社は、本サービスを用いて閲覧、取得された他の事業者または個人のウェブサービスにおけるお客様の取得情報等の保護に関しまして、一切の責任を請け負いません。
      </p>
      <ol>
        <li>
          お客様ご自身にて当該サービスの内容をよくご確認の上で、閲覧およびご利用をお願いいたします。
        </li>
        <li>
          ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合に関してまして、当社は一切の責任を請け負いません。
        </li>
        <li>
          お客様に対して、ご本人確認をするための情報につきましては、お客様にて、紛失、忘失または第三者に知られることのないよう厳重な管理をお願いいたします。
        </li>
      </ol>
    </section>
    <section>
      <h2>海外からのアクセスについて</h2>
      <p>
        本サービスの利用、管理、運用方法および掲載内容が不適切または違法とされる国または地域からの本サービスへのアクセスはご遠慮下さい。
      </p>
    </section>
    <section>
      <h2>
        プライバシーポリシーの改善および最新のプライバシーポリシーの確認について
      </h2>
      <p>
        当社はプライバシーポリシー関しまして、適宜その改善に取り組みます。お客様は、本サービスにアクセスするか当社のサービスをご利用になる前に、必ず最新のプライバシーポリシーをご確認ください。お客様が本サービスにアクセスされるか当社のサービスをご利用になられた場合は、最新のプライバシーポリシーの内容に同意されたものとみなします。
      </p>
    </section>
    <section>
      <h2>運営事業者</h2>
      <p>メイキットシステム</p>
    </section>
  </div>
)
export default Page
